// Menu Section Icons
import { ReactElement } from 'react';
import {
  BRAND,
  backSideIcon,
  dekraIcon,
  documentIcon,
  exteriorIcon,
  frontSideIcon,
  interiorIcon,
  interiorPlaceIcon,
  leftSideIcon,
  plateIcon,
  rightSideIcon,
  scanIcon,
  speedometerIcon,
  trunkIcon,
  uploadIcon,
  video360Icon,
  vinIcon,
} from '../static/constants/brands/brand';
import { Company } from '../interfaces/brand';
import { brandingInfo } from '../static/constants/brands';
import { IPreInspectionModel } from '../types';

// ===== Media type files
export enum MediaSection {
  VEHICLE_EXTERIOR = 40,
  VEHICLE_INTERIOR = 41,
  VEHICLE_VIDEO = 42,
  DOCUMENTS = 43,
  DEKRA = 43,
  TRUNK = 41,
  RUV = 83,
  ACCESSORIES = 84,
}

export enum MediaId {
  VEHICLE_EXTERIOR_PLATE = 'VEHICLE_EXTERIOR_PLATE',
  VEHICLE_EXTERIOR_FRONT = 'VEHICLE_EXTERIOR_FRONT',

  VEHICLE_EXTERIOR_RIGHT = 'VEHICLE_EXTERIOR_RIGHT',
  VEHICLE_EXTERIOR_RIGHT_FRONT = 'VEHICLE_EXTERIOR_RIGHT_FRONT',
  VEHICLE_EXTERIOR_RIGHT_BACK = 'VEHICLE_EXTERIOR_RIGHT_BACK',
  VEHICLE_EXTERIOR_BACK = 'VEHICLE_EXTERIOR_BACK',
  VEHICLE_EXTERIOR_LEFT = 'VEHICLE_EXTERIOR_LEFT',
  VEHICLE_EXTERIOR_LEFT_FRONT = 'VEHICLE_EXTERIOR_LEFT_FRONT',
  VEHICLE_EXTERIOR_LEFT_BACK = 'VEHICLE_EXTERIOR_LEFT_BACK',

  VEHICLE_INTERIOR_ODOMETER = 'VEHICLE_INTERIOR_ODOMETER',
  VEHICLE_INTERIOR_VIN = 'VEHICLE_INTERIOR_VIN',
  VEHICLE_INTERIOR_MOTOR = 'VEHICLE_INTERIOR_MOTOR',
  VEHICLE_INTERIOR_1 = 'VEHICLE_INTERIOR_1',
  VEHICLE_INTERIOR_2 = 'VEHICLE_INTERIOR_2',

  VEHICLE_VIDEO = 'VEHICLE_VIDEO',

  DOCUMENTS_VEHICLE_PROPERTY_REGISTRY = 'DOCUMENTS_VEHICLE_PROPERTY_REGISTRY',
  DOCUMENTS_CEDULA = 'DOCUMENTS_CEDULA',
  COUNTERPART_CEDULA = 'COUNTERPART_CEDULA',
  DOCUMENTS_COUNTERPART_CEDULA_CR = 'DOCUMENTS_COUNTERPART_CEDULA_CR',
  DOCUMENTS_BACK_CEDULA_CR = 'DOCUMENTS_BACK_CEDULA_CR',
  DOCUMENTS_DRIVERS_LICENSE = 'DOCUMENTS_DRIVERS_LICENSE',
  DOCUMENTS_DRIVERS_PORTRAIT = 'DOCUMENTS_DRIVERS_PORTRAIT',
  VEHICLE_INTERIOR_MOTOR_PANO = 'VEHICLE_INTERIOR_MOTOR_PANO',
  TRUNK = 'TRUNK',
  DEKRA = 'DEKRA',
  ACCESSORIES = 'ACCESSORIES',
}

export enum ValidationError {
  IS_BLURRY = 'IS_BLURRY',
  NO_DOCUMENT = 'NO_DOCUMENT',
  NO_VEHICLE = 'NO_VEHICLE',
  NO_PLATE = 'NO_PLATE',
  NO_VIN = 'NO_VIN',
  NO_ODOMETER = 'NO_ODOMETER',
}

export interface PhotoDefinition {
  placeholder: any;
  title: string;
  shortTitle?: string;
  exampleImageUrl: string;
  isVisible: boolean;
  isRequired: boolean;
  useGallery: boolean;
  alertDescription?: string;
}

export const PHOTO_DEFINITIONS: { [key in MediaId]: PhotoDefinition } = {
  [MediaId.VEHICLE_EXTERIOR_PLATE]: {
    placeholder: (
      <img
        src={plateIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Placa',
    shortTitle: 'Placa',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Placa fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_FRONT]: {
    placeholder: (
      <img
        src={frontSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Frontal',
    shortTitle: 'Frontal',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Frontal fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_RIGHT]: {
    placeholder: (
      <img
        src={rightSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Lateral derecho',
    shortTitle: 'Lateral derecho',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Lateral Derecha fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_RIGHT_FRONT]: {
    placeholder: (
      <img
        src={rightSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Lateral derecho Frontal',
    shortTitle: 'Lateral derecho Frontal',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Lateral Derecha Frontal fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_RIGHT_BACK]: {
    placeholder: (
      <img
        src={rightSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Lateral derecho Trasero',
    shortTitle: 'Lateral derecho Trasero',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Lateral Derecha Trasero fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_BACK]: {
    placeholder: (
      <img
        src={backSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Trasera',
    shortTitle: 'Trasera',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Trasera fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_LEFT]: {
    placeholder: (
      <img
        src={leftSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Lateral izquierdo',
    shortTitle: 'Lateral izquierdo',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Lateral Izquierda fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_LEFT_FRONT]: {
    placeholder: (
      <img
        src={leftSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Lateral izquierdo frontal',
    shortTitle: 'Lateral izquierdo frontal',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Lateral Izquierda Frontal fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_EXTERIOR_LEFT_BACK]: {
    placeholder: (
      <img
        src={leftSideIcon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Lateral izquierdo trasero',
    shortTitle: 'Lateral izquierdo trasero',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Lateral Izquierda Trasera fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_INTERIOR_ODOMETER]: {
    placeholder: (
      <img
        src={speedometerIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Odómetro',
    shortTitle: 'Odómetro',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Odómetro fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_INTERIOR_VIN]: {
    placeholder: (
      <img
        src={vinIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'VIN (Número de Chasis)',
    shortTitle: 'VIN',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en VIN fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_INTERIOR_MOTOR]: {
    placeholder: (
      <img
        src={vinIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Número Motor',
    shortTitle: 'Número Motor',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: false,
    useGallery: false,
    alertDescription:
      'Foto usada en Número Motor fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_INTERIOR_1]: {
    placeholder: (
      <img
        src={interiorPlaceIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Interior Frontal',
    shortTitle: 'Interior Frontal',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Interior Frontal fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_INTERIOR_2]: {
    placeholder: (
      <img
        src={interiorPlaceIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Interior Trasera',
    shortTitle: 'Interior Trasera',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Zona Interior Trasera fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.VEHICLE_VIDEO]: {
    placeholder: (
      <img
        src={video360Icon[BRAND]}
        className="theme-svg-icon"
        width={60}
        height={60}
      />
    ),
    title: 'Video',
    shortTitle: 'Video',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
  },
  [MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Registro de propiedad vehicular',
    shortTitle: 'RUV',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: true,
    alertDescription:
      'Foto usada en Registro Vehícular fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.DOCUMENTS_CEDULA]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Cédula de identidad',
    shortTitle: 'Cédula',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: true,
    alertDescription:
      'Foto usada en Documento de Identificación fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.DOCUMENTS_COUNTERPART_CEDULA_CR]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Cédula de identidad frontal',
    shortTitle: 'Cédula de identidad frontal',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Documento de Identificación fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.COUNTERPART_CEDULA]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Cédula de identidad',
    shortTitle: 'Cédula',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Documento de Identificación fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.DOCUMENTS_DRIVERS_LICENSE]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Licencia de conducir',
    shortTitle: 'Licencia',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: true,
    alertDescription:
      'Foto usada en Documento de Conducir fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.DOCUMENTS_DRIVERS_PORTRAIT]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Retrato',
    shortTitle: 'Retrato',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Retrato de Usuario fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.DEKRA]: {
    placeholder: (
      <img
        src={dekraIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Hoja de revisión técnica',
    shortTitle: 'Hoja de revisión técnica',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Hoja de revisión técnica vehícular (Subida por reintentos).',
  },
  [MediaId.TRUNK]: {
    placeholder: (
      <img
        src={trunkIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Cajuela',
    shortTitle: 'Cajuela',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Hoja de revisión técnica vehícular (Subida por reintentos).',
  },
  [MediaId.VEHICLE_INTERIOR_MOTOR_PANO]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Panorámica Motor',
    shortTitle: 'Panorámica Motor',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Panorámica Motor fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.DOCUMENTS_BACK_CEDULA_CR]: {
    placeholder: (
      <img
        src={scanIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Cédula de identidad trasera',
    shortTitle: 'Cédula de identidad trasera',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: true,
    useGallery: false,
    alertDescription:
      'Foto usada en Cédula de identidad trasera fue detectada como no válida por la galería (Subida por reintentos).',
  },
  [MediaId.ACCESSORIES]: {
    placeholder: (
      <img
        src={uploadIcon[BRAND]}
        className="theme-svg-icon"
        width={50}
        height={50}
      />
    ),
    title: 'Tomar nueva fotografía',
    exampleImageUrl: '',
    isVisible: true,
    isRequired: false,
    useGallery: false,
  },
};

// ===== Photo Sections
export enum PhotoSectionId {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  VIDEO = 'VIDEO',
  DOCUMENTS = 'DOCUMENTS',
  SURA_PHOTOS = 'SURA_PHOTOS',
  ACCESSORIES = 'ACCESSORIES',
}

export function photoSectionIdFromUrlSlug(id: string): PhotoSectionId {
  return id.toUpperCase() as PhotoSectionId;
}

export interface PhotoSectionDefinition {
  icon: ReactElement;
  title: string;
  photoIds: MediaId[];
  helpModal?: ReactElement;
  visible: boolean;
}

export const PHOTO_SECTIONS: {
  [key in PhotoSectionId]: PhotoSectionDefinition;
} = {
  [PhotoSectionId.EXTERIOR]: {
    icon: (
      <img
        src={exteriorIcon[BRAND]}
        className="theme-svg-icon"
        width={30}
        height={30}
      />
    ),
    visible: Company.SURA !== BRAND,
    title: 'Exterior del vehículo',
    photoIds: brandingInfo[BRAND].galleryExterior,
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>
            Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
          </li>
          <li>Debe verse el vehículo completo.</li>
          <li>El lugar donde realizas la inspección debe estar iluminado.</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.INTERIOR]: {
    icon: (
      <img
        src={interiorIcon[BRAND]}
        className="theme-svg-icon"
        width={30}
        height={30}
      />
    ),
    visible: Company.SURA !== BRAND,
    title: 'Interior del vehículo',
    photoIds: brandingInfo[BRAND].galleryInterior,
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>Buena iluminación.</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.ACCESSORIES]: {
    icon: (
      <img
        src={documentIcon[BRAND]}
        className="theme-svg-icon"
        width={30}
        height={30}
      />
    ),
    visible: Company.BCR === BRAND,
    title: 'Accesorios',
    photoIds: [MediaId.ACCESSORIES],
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>Buena iluminación.</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.DOCUMENTS]: {
    icon: (
      <img
        src={documentIcon[BRAND]}
        className="theme-svg-icon"
        width={30}
        height={30}
      />
    ),
    visible: Company.SURA !== BRAND,
    title: 'Documentos',
    photoIds: brandingInfo[BRAND].galleryDocument,
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>Colocar el documento sobre una superficie plana.</li>
          <li>
            Contar con buena iluminación o habilita el flash del dispositivo.
          </li>
          <li>
            Tomar la foto lo más cerca posible{' '}
            <b>(debe verse el documento completo)</b>.
          </li>
          <li>La información en el documento debe ser legible</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.VIDEO]: {
    icon: (
      <img
        src={video360Icon[BRAND]}
        className="theme-svg-icon"
        width={30}
        height={30}
      />
    ),
    visible: Company.SURA === BRAND ? false : brandingInfo[BRAND].videoVisible,
    title: 'Video 360°',
    photoIds: [MediaId.VEHICLE_VIDEO],
    helpModal: (
      <div>
        <p>Toma las siguientes consideraciones a la hora de tomar el video:</p>
        <ul>
          <li>
            Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
          </li>
          <li>Debe verse el vehículo completo.</li>
          <li>El lugar donde realizas la inspección debe estar iluminado.</li>
        </ul>
      </div>
    ),
  },
  [PhotoSectionId.SURA_PHOTOS]: {
    icon: (
      <img
        src={exteriorIcon[BRAND]}
        className="theme-svg-icon"
        width={30}
        height={30}
      />
    ),
    title: 'Fotos del vehículo',
    visible: Company.SURA === BRAND,
    photoIds: [
      MediaId.VEHICLE_EXTERIOR_FRONT,
      MediaId.VEHICLE_EXTERIOR_RIGHT,
      MediaId.VEHICLE_EXTERIOR_BACK,
      MediaId.VEHICLE_EXTERIOR_LEFT,
      MediaId.VEHICLE_INTERIOR_VIN,
      MediaId.VEHICLE_INTERIOR_1,
    ],
    helpModal: (
      <div>
        <p>
          Toma las siguientes consideraciones a la hora de tomar las
          fotografías:
        </p>
        <ul>
          <li>
            Contar con el vehículo limpio <b>(sin barro ni polvo)</b>.
          </li>
          <li>Debe verse el vehículo completo.</li>
          <li>El lugar donde realizas la inspección debe estar iluminado.</li>
        </ul>
      </div>
    ),
  },
};

export const isValidPhotoSectionsByBrand = (
  section: MediaId,
  model: IPreInspectionModel,
  currentValue: boolean,
  brand: string,
): boolean => {
  if (brand === Company.IS) {
    const { preActiveQuestionBroker } = model;
    const preIsBroker = localStorage.getItem('preIsBroker') === 'true';

    if (preActiveQuestionBroker === true && preIsBroker === true) {
      return [
        MediaId.VEHICLE_EXTERIOR_FRONT,
        MediaId.VEHICLE_EXTERIOR_RIGHT,
        MediaId.VEHICLE_EXTERIOR_BACK,
        MediaId.VEHICLE_EXTERIOR_LEFT,
        MediaId.VEHICLE_INTERIOR_ODOMETER,
        MediaId.VEHICLE_INTERIOR_VIN,
        MediaId.VEHICLE_INTERIOR_MOTOR_PANO,
        MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY,
        MediaId.DOCUMENTS_CEDULA,
        MediaId.DOCUMENTS_DRIVERS_LICENSE,
      ].includes(section);
    } else if (preActiveQuestionBroker === true && preIsBroker === false) {
      return [
        MediaId.VEHICLE_EXTERIOR_FRONT,
        MediaId.VEHICLE_EXTERIOR_RIGHT,
        MediaId.VEHICLE_EXTERIOR_BACK,
        MediaId.VEHICLE_EXTERIOR_LEFT,
        MediaId.VEHICLE_INTERIOR_ODOMETER,
        MediaId.VEHICLE_INTERIOR_VIN,
        MediaId.VEHICLE_INTERIOR_MOTOR_PANO,
        MediaId.DOCUMENTS_DRIVERS_PORTRAIT,
        MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY,
        MediaId.DOCUMENTS_CEDULA,
        MediaId.DOCUMENTS_DRIVERS_LICENSE,
        MediaId.VEHICLE_VIDEO,
      ].includes(section);
    } else {
      return [
        MediaId.VEHICLE_EXTERIOR_FRONT,
        MediaId.VEHICLE_EXTERIOR_RIGHT,
        MediaId.VEHICLE_EXTERIOR_BACK,
        MediaId.VEHICLE_EXTERIOR_LEFT,
        MediaId.VEHICLE_INTERIOR_ODOMETER,
        MediaId.VEHICLE_INTERIOR_VIN,
        MediaId.VEHICLE_INTERIOR_MOTOR_PANO,
        MediaId.DOCUMENTS_DRIVERS_PORTRAIT,
        MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY,
        MediaId.DOCUMENTS_CEDULA,
        MediaId.DOCUMENTS_DRIVERS_LICENSE,
        MediaId.VEHICLE_VIDEO,
      ].includes(section);
    }
  } else if (brand === Company.CONNECT) {
    return [
      MediaId.VEHICLE_EXTERIOR_FRONT,
      MediaId.VEHICLE_EXTERIOR_RIGHT,
      MediaId.VEHICLE_EXTERIOR_BACK,
      MediaId.VEHICLE_EXTERIOR_LEFT,
      MediaId.VEHICLE_INTERIOR_ODOMETER,
      MediaId.VEHICLE_INTERIOR_VIN,
      MediaId.VEHICLE_INTERIOR_MOTOR_PANO,
      MediaId.DOCUMENTS_DRIVERS_PORTRAIT,
      MediaId.DOCUMENTS_VEHICLE_PROPERTY_REGISTRY,
      MediaId.DOCUMENTS_CEDULA,
      MediaId.DOCUMENTS_DRIVERS_LICENSE,
      MediaId.VEHICLE_VIDEO,
    ].includes(section);
  } else if (brand === Company.SURA) {
    return [
      MediaId.VEHICLE_EXTERIOR_FRONT,
      MediaId.VEHICLE_EXTERIOR_RIGHT,
      MediaId.VEHICLE_EXTERIOR_BACK,
      MediaId.VEHICLE_EXTERIOR_LEFT,
      MediaId.VEHICLE_INTERIOR_VIN,
      MediaId.VEHICLE_INTERIOR_1,
    ].includes(section);
  }

  return currentValue;
};
